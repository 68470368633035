<template>
  <v-row align="center" justify="center">
    <v-col cols="12">
      <v-card elevation="0" tile>
        <v-card-title class="grey lighten-4">
          {{ $t("hr.menu.inbox") }}
          <v-spacer />
          <!-- <id-button class="mr-2" @click="sendMessageDialogStatus = true">
            <template #icon>
              <v-icon left>mdi-send</v-icon>
            </template>
            {{ $t("hr.message.send_message") }}
          </id-button> -->
          <id-button @click="onRefreshData">
            <template #icon>
              <v-icon left>mdi-refresh</v-icon>
            </template>
            {{ $t("buttons.refresh") }}
          </id-button>
        </v-card-title>
        <v-data-table
          :headers="table.headers"
          :items="table.data"
          :options.sync="table.options"
          :server-items-length="table.totalItems"
          single-select
          class="elevation-1"
          :loading="table.loading"
          :footer-props="{ 'items-per-page-options': [10, 25, 50, 100] }"
          single-expand
        >
          <template #item="row">
            <tr>
              <td>{{ row.item.title || "-" }}</td>
              <td>
                <employee-profile :employee="row.item.sender" />
              </td>
              <td>{{ $t(`hr.message.message_type_enum.${row.item.messageType}`) }}</td>
              <td>{{ $t(`hr.message.message_importance_enum.${row.item.importance}`) }}</td>
              <td>{{ row.item.createdDateTime | formattedDateTimeMinute }}</td>
              <td class="text-right">
                <v-btn icon @click.stop.prevent="row.expand(!row.isExpanded)">
                  <v-icon v-if="row.isExpanded">mdi-chevron-up</v-icon>
                  <v-icon v-else>mdi-chevron-down</v-icon>
                </v-btn>
              </td>
            </tr>
          </template>
          <template #expanded-item="{ headers, item }">
            <tr>
              <td :colspan="headers.length" class="pa-0">
                <v-card flat class="grey lighten-4">
                  <v-card-text>
                    <v-row>
                      <v-col cols="12" sm="2">
                        <strong>{{ $t("hr.message.body") }}</strong>
                      </v-col>
                      <v-col cols="12" sm="10">
                        {{ item.body }}
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </td>
            </tr>
          </template>
        </v-data-table>
        <send-message-dialog v-model="sendMessageDialogStatus" @message:send="fetchItems()" />
        <transition name="slide-in-left">
          <router-view @success:save="onRefreshData" />
        </transition>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
  /**
   * @author Ali Hüseyin ŞİRELİ
   * @email ali.sireli@globme.com.tr
   * @create date 2020-12-30 15:05:19
   * @modify date 2020-12-30 15:05:19
   * @desc Inbox
   */

  import { MESSAGES } from "../query";

  export default {
    name: "Inbox",
    components: {
      SendMessageDialog: () => import("../components/SendMessageDialog")
    },
    data: (vm) => ({
      sendMessageDialogStatus: false,
      table: {
        loading: false,
        data: [],
        criteria: {
          receiver: null
        },
        options: {},
        pagination: {
          rowsPerPage: 50,
          sortBy: "createdDateTime",
          descending: true
        },
        totalItems: 0,
        totalPages: 0,
        selected: [],
        headers: [
          {
            text: vm.$t("hr.message.title"),
            value: "title",
            align: "left",
            sortable: true,
            class: ["primary--text"]
          },
          {
            text: vm.$t("hr.message.sender"),
            value: "sender.firstName",
            align: "left",
            sortable: true,
            class: ["primary--text"]
          },
          {
            text: vm.$t("hr.message.message_type"),
            value: "messageType",
            align: "left",
            sortable: true,
            class: ["primary--text"]
          },
          {
            text: vm.$t("hr.message.importance"),
            value: "importance",
            align: "left",
            sortable: true,
            class: ["primary--text"]
          },
          {
            text: vm.$t("hr.message.date"),
            value: "createdDateTime",
            align: "left",
            sortable: true,
            class: ["primary--text"]
          },
          {
            align: "end"
          }
        ]
      }
    }),
    watch: {
      "table.options": {
        handler(options) {
          let { itemsPerPage, page } = options;
          this.table.pagination.rowsPerPage = itemsPerPage;
          this.table.pagination.page = page;
          this.table.criteria.receiver = this.$store.state.auth.user.id;
          this.fetchItems();
        },
        deep: true
      }
    },
    methods: {
      onRefreshData() {
        this.fetchItems();
      },
      fetchItems() {
        this.table.loading = true;
        this.$apollo
          .query({
            query: MESSAGES,
            variables: {
              criteria: this.table.criteria,
              pagination: this.$helpers.convertPagination(this.table.pagination)
            }
          })
          .then(({ data, errors }) => {
            if (!errors || !data.error) {
              this.table.data = data.filterWithPaginateMessages.content || [];
              this.table.totalItems = data.filterWithPaginateMessages.pageInfo.totalElements || 0;
            }
          })
          .catch((e) => {
            this.$helpers.showNotification(e.message);
          })
          .finally(() => (this.table.loading = false));
      }
    }
  };
</script>

<style scoped></style>
